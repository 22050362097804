
import './App.css';
export default function App() {
  return (
    <div class="main">
      <div class="menu-container">
        <div class="container">
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-start align-items-center menu">
              <a class="logo" href="/">
                <img class="d-sm-none" src={process.env.PUBLIC_URL + '/logo.png'} />
                <img class="d-none d-sm-block" src={process.env.PUBLIC_URL + '/logo_full.png'} />
              </a>
            </div>
          </div>
        </div> 
      </div>

      <div class="d-flex align-items-center content">
        <div class="container">
          <div class="row justify-content-around">
            <div class="col-12 col-sm-12 col-md-5 order-2 order-sm-2 order-md-1 mb-5 mt-5 d-flex justify-content-center">
              <h1>Page not found ;(</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-container">
        <div class="container">
          <div class="row">
            <div class="col-6 footer-block d-flex align-items-center">
              <div class="footer-text">© YogaLea 2022</div>
            </div>
            <div class="col-6 footer-block d-flex align-items-center justify-content-end">
              <a href="/terms.pdf" target="_blank" class="ml-3">Terms of use</a>
              <a href="/privacy.pdf" target="_blank" class="ml-3">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
