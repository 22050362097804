
import './App.css';

function App() {
  return (
    <div class="main">
      <div class="menu-container">
        <div class="container">
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-start align-items-center menu">
              <a class="logo" href="/">
                <img class="d-sm-none" src={process.env.PUBLIC_URL + '/logo.png'} />
                <img class="d-none d-sm-block" src={process.env.PUBLIC_URL + '/logo_full.png'} />
              </a>
            </div>
          </div>
        </div> 
      </div>

      <div class="d-flex align-items-center content">
        <div class="container">
          <div class="row justify-content-around">
            <div class="col-12 col-sm-12 col-md-5 order-2 order-sm-2 order-md-1 mb-5 mt-5 d-flex justify-content-center">
              <img src={process.env.PUBLIC_URL + '/phones_bg.png'} class="img-fluid" />
            </div>
            <div class="col-12 col-sm-12 col-md-6 order-1 order-sm-1 order-md-2 mb-5 mt-5 d-flex flex-column justify-content-center">
              <h1 class="h1 text-center text-md-left mb-4">Yoga Lea</h1>
              <p class="text-center text-md-left mb-5">Realize the possibilities of your body, achieve your goals and be happy with smart virtual coach YOGA LEA.</p>
              <div class="row pt-4">
                <div class="col-6 col-sm-6 col-md-5">
                  <a href="https://apps.apple.com/ge/app/yoga-lea-health-fitness-app/id1659810922">
                    <img src={process.env.PUBLIC_URL + '/appstore.png'} class="img-fluid"/>
                  </a>
                </div>
                <div class="col-6 col-sm-6 col-md-5">
                  <a href="https://play.google.com/store/apps/details?id=com.yogalea">
                    <img src={process.env.PUBLIC_URL + '/googleplay.png'} class="img-fluid"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      


      <div class="footer-container">
        <div class="container">
          <div class="row">
            <div class="col-6 footer-block d-flex align-items-center">
              <div class="footer-text">© YogaLea 2022</div>
            </div>
            <div class="col-6 footer-block d-flex align-items-center justify-content-end">
              <a href="https://cdn.yogalea.app/content/terms.html" target="_blank" class="ml-3">Terms of use</a>
              <a href="https://cdn.yogalea.app/content/privacy.html" target="_blank" class="ml-3">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default App;
